@import "app/styles/bootstrap/variables"
@import "app/styles/mixins"
@import "app/styles/style-flat-variables"

#home-view
  $sub-headline-font: "Work Sans", "Open Sans", "sans serif"
  $body-font: "Work Sans", "Open Sans", "sans serif"
  $pitch: #131B25
  $teal-light-1: #1FBAB4
  $teal-light-2: #6AE8E3
  $teal-dark: #0E4C60
  display: none

  // style-flat overwrites
  h1, .text-h1
    font-family: $pixel-headline-font
    font-weight: bold
    font-size: 56px
    line-height: 64px
    margin-bottom: -6px
    color: $navy

  h2, .text-h2
    font-family: $pixel-headline-font
    font-weight: bold
    font-size: 33px
    line-height: 62px
    letter-spacing: 2.58px

  h3, .text-h3
    font-family: $sub-headline-font
    font-weight: normal
    font-size: 26px
    line-height: 32px
    letter-spacing: 0.56px
    color: #131825

  h4, .text-h4
    font-family: $sub-headline-font
    font-weight: 600
    font-size: 28px
    line-height: 38px
    letter-spacing: 0.56px
    color: black
    margin-bottom: 14px

  h5, .text-h5
    font-family: $body-font
    font-size: 24px
    line-height: 30px
    letter-spacing: 0.48px
    font-weight: normal

  p, .text-p
    font-family: $body-font
    font-size: 22px
    font-weight: normal
    letter-spacing: 0
    line-height: 30px
    color: $pitch

  .btn-primary, .btn-navy, .btn-teal, .crt-widget-waterfall button.crt-load-more
    background-color: $teal
    border-radius: 4px
    color: $gray
    text-shadow: unset
    font-weight: bold
    letter-spacing: 0.71px
    line-height: 24px
    min-width: 247px
    &:hover
      background-color: #2DCEC8
      transition: background-color .35s

  .btn-primary-alt, .btn-navy-alt, .btn-teal-alt
    background-color: transparent
    border: 2px solid $teal
    color: $teal
    border-radius: 4px
    text-shadow: unset
    font-weight: bold
    letter-spacing: 0.71px
    line-height: 24px
    min-width: 247px
    &:hover
      background-color: $teal
      box-shadow: unset
      color: $gray
      transition: color .35s, background-color .35s, box-shadow .35s

  overflow: hidden

  // TODO: pull out common pieces, such as carousel controls

  #site-content-area
    background: linear-gradient(262.39deg, #D7EFF2 -1.56%, #FDFFFF 95.05%)
    // For joining with footer seamlessly
    margin-bottom: -50px

    .row
      padding: 62px 0px

      &.video-row
        padding: 0 0 40px 0

      &.subhead-row
        padding: 17px 0 24px 0

      &.get-started-row
        padding: 72px 0 15px 0

      &.for-educators-row
        padding: 10px 0 50px 0

      &.product-row
        padding: 0 31px

        &.codecombat-classroom-row
          padding-top: 70px

        .row
          padding: 0

      &.for-parents-row
        padding: 0 0 50px 0

      &.for-everyone-row
        padding: 0 0 50px 0

      &.community-row
        padding: 91px 0 10px 0

      &.testimonials-row
        padding: 20px 0 0 0

      &.curator-row
        padding: 58px 0 10px 0

    .width-container
      max-width: 1170px
      float: unset
      margin: 0 auto

    #top-banner
      background-color: $teal-light-1
      padding: 10px 0px
      text-align: center
      color: $teal-dark
      .row
        max-width: 1170px
        float: unset
        margin: 0 auto
        padding: 0px
      a
        margin-left: 10px
        color: #FFFFFF
        text-decoration: underline

      .top-banner-img
        position: absolute
        height: 120px
        left: -20px
        transform: scaleX(-1)

        @media (max-width: 768px)
          display: none

        @media (max-width: 1100px)
          left: 0px

    .btn-gold
      background-color: $gold
      &:hover
        background-color: #FDD147

    .cloud-wrapper
      background-image: url(/images/pages/parents/image_cloud_3.svg), url(/images/pages/parents/image_cloud_4.svg), url(/images/pages/parents/image_cloud_3.svg), url(/images/pages/parents/image_cloud_1.svg)
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat
      background-size: 260px, 90px, 260px, 150px
      background-position: top 10px left 1%, top 17px right 50%, top 335px left 37%, top 33px right calc(10px + 2%)

      @media screen and (max-width: 991px)
        background: transparent

    #jumbotron-container-fluid
      background-image: url(/images/pages/home-v2/hero-image.png)
      background-repeat: no-repeat
      background-size: 616px
      background-position: 100% 86px
      height: 591px
      max-width: 1226px
      margin: auto
      padding-bottom: 0
      h1
        margin: 54px 64px 50px 0
      .button-section
        width: 250px
        text-transform: uppercase
        .btn-lg
          font-size: 20px
        .btn-primary
          background-color: $gold
          &:hover
            background-color: #FDD147
        .btn-primary-alt
          border-color: $gold
          color: $gold
          &:hover
            background-color: $gold
            color: $gray
        p
          margin-bottom: 20px

      @media screen and (max-width: 991px)
        background-size: 50%
        background-position: 100% 100%

      @media screen and (max-width: 600px)
        background-size: 100%
        background-position: 100% 25%

        h1
          text-shadow: 2px 0 0 white, -2px 0 0 white, 0 -2px 0 white, 0 2px 0 white
          text-align: center

        .button-section
          margin: 0 auto

    .video-container
      position: relative
      padding-top: 56.25%
      box-shadow: 0 0 30px $teal-dark

    .container-graphic-spacer
      pointer-events: none
      overflow-x: hidden

      .row
        padding: 5px 0

    .container-graphic-spacer img
      margin: 0 auto

    #tent-img
      position: absolute
      top: -70px

      @media screen and (max-width: 1200px)
        display: none

    #ozaria-spacer-img
      position: relative
      max-width: 287px
      left: 27%
      top: -85px
      margin-bottom: -160px

    #codecombat-spacer-img
      position: relative
      max-width: 262px
      left: 49.8%
      top: -15px
      margin-bottom: 0

    #online-classes-spacer-img
      position: relative
      max-width: 289px
      left: 43%
      top: -15px
      margin-bottom: -15px

    .product-logo
      margin-bottom: 20px

      @media screen and (max-width: 600px)
        margin: 0px auto 20px auto

    .product-title
      @media screen and (max-width: 600px)
        text-align: center

    .product-btn
      @media screen and (max-width: 600px)
        text-align: center

    .centered-flex
      display: flex
      align-items: center
      justify-content: center

    .community-container
      .row
        padding: 50px 0 10px 0

      h4
        margin-bottom: 0

      p
        font-size: 18px
        line-height: 22px

    .community-graphics
      background-image: url(/images/pages/parents/image_cloud_3.svg), url(/images/pages/parents/image_cloud_3.svg), url(/images/pages/parents/image_cloud_1.svg), url(/images/pages/parents/image_cloud_4.svg)
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat
      background-position: top 0px left 30px, top 40px right -50px, bottom right 10%, bottom left 17%
      background-size: 300px, 260px, 250px, 150px

    .testimonials-container
      @media screen and (max-width:700px)
        display: none

        & + .container-graphic-spacer
          display: none

    .crt-widget-waterfall
      padding-bottom: 0

      // Tighten the spacing on these up
      .crt-post-header
        padding-top: 10px
        margin-bottom: 10px
        text-align: left

        .crt-social-icon
          float: right

      .crt-post-text
        text-align: left

      .crt-load-more
        text-transform: capitalize

    // If we wanted to define the element height and bottom edge ourselves (conflicts with their Load More), we could do something like this.
    //  .crt-feed
    //    transition: max-height .5s
    //    max-height: 600px
    //    overflow: hidden
    //
    //  &.extended .crt-feed
    //    max-height: initial
    //
    //  &:not(.extended) .crt-feed::after
    //    width: 100%
    //    height: 50px
    //    bottom: 100px
    //    position: absolute
    //    z-index: 1
    //    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%)

    #featured-in-awards
      margin-top: 10px

      h1
        font-size: 40px
        line-height: 43px

      .row
        min-height: 316px
        padding: 33px 0 0 0

      .centered-flex
        height: 150px

      .featured-in, .awards-partners
        border: 4px dashed #F2D269
        padding: 50px 0 20px 0

      .featured-in
        border-radius: 20px 0 0 20px
        border-right: unset

      .awards-partners
        border-radius: 0 20px 20px 0
        .centered-flex
          flex-direction: column
          color: $teal-dark

          p
            margin-top: 10px
            font-size: 13px
            line-height: 17px
            letter-spacing: 0.25px

      @media screen and (max-width: 991px)
        .featured-in, .awards-partners
          border: unset

    .row.row-eq-height
      display: flex
      flex-wrap: wrap

    .row.row-eq-height > [class*='col-']
      display: flex
      flex-direction: column

    .container-background-faq
      margin-top: 50px
      margin-bottom: 50px
      .row.row-eq-height
        padding: 0

      .row h4
        margin-top: 30px

      .row.faq-links-row
        margin: 0
        padding: 0
        text-align: center

        p
          font-size: 18px
          line-height: 22px
          color: black

      & > .container
        border: 4px solid $teal-light-2
        border-radius: 40px
        border-top: none
        position: relative
        padding: 30px

        & > div:last-of-type
          margin: 32px 0 0

      h4
        font-size: 16px
        line-height: 17px
        letter-spacing: 0.333px

      p, ol
        font-size: 14px
        line-height: 18px
        letter-spacing: 0.267px
        color: $pitch

    .container-background-header
      position: absolute
      transform: translateY(-60px)
      width: calc(100% - 30px)

    /* These create the broken top border which FAQ sits between */
    /* Top left border */
    .container-background-faq > .container::after
      content: ''
      position: absolute
      height: 100px
      width: 37%
      border-top: 4px solid $teal-light-2
      border-left: 4px solid $teal-light-2
      top: 0
      left: -4px
      border-radius: 40px 0 0 0

    /* Top right border */
    .container-background-faq > .container::before
      content: ''
      position: absolute
      height: 100px
      width: 37%
      border-top: 4px solid $teal-light-2
      border-right: 4px solid $teal-light-2
      top: 0
      right: -4px
      border-radius: 0px 40px 0 0

    .carousel-dot
      display: inline-block
      width: 13px
      height: 13px
      border-radius: 6.5px
      margin: 5px
      background-color: #C4C4C4

      &:not(.active)
        cursor: pointer

      &.active
        background-color: $teal-light-1
        cursor: default

    #game-based-carousel > .carousel-container.container, #student-quotes-carousel > .container
      border-radius: 38px
      border: 4px solid $teal-light-2
      padding: 16px

      .carousel-inner
        height: 521px

        .row
          padding: 32px 0 0
          display: flex
          align-items: center

          p
            font-family: $body-font

            b
              font-size: 17px
              line-height: 21px

    #student-quotes-carousel
      margin-top: 146px

      > .container .carousel-inner
        height: 406px

    #game-based-carousel > .carousel-tabs-container.container
      margin-top: 15px
      padding: 0 30px

      ul
        border: 0

        li
          margin: 0 10px
          width: 350px
          padding: 16px 0 0 0
          height: 60px
          border-radius: 10px 10px 0 0
          background-color: $teal-dark
          text-align: center

          h2
            font-size: 26px
            line-height: 28px
            letter-spacing: 0.005em
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            color: white

          &:not(.active)
            cursor: pointer

          &.active
            background-color: $teal-light-2

            h2
              color: $teal-dark

      @media screen and (max-width: 1200px)
        display: none

  .container-footer-mountains
    width: 100%
    background: url(/images/pages/parents/parents_footer_mountain.svg)
    background-repeat: no-repeat
    background-position: top
    background-size: cover
    height: 170px
    margin-bottom: -50px
