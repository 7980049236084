//
  Layout for CreateAccountModal is done largely with flexboxes.
  Rules in this file should be ones that apply to all screens/subviews, but this
  separation may not be perfect.

  Currently it uses .modal-dialog, .modal-content, etc, for some parts of the modal.
  Unfortunately those preexesting classes don't line up perfectly with the needs of this modal,
  so many of the other styles for those classes don't apply or are overridden.

@import "app/styles/style-flat-variables"
@import "app/styles/bootstrap/variables"

#create-account-modal
  .modal-dialog
    width: auto
    max-width: fit-content
    min-width: 850px
    @media (max-height: 800px)
      margin-top: 0


    &.ozaria-modal
      box-shadow: 0 5px 15px rgb(0 0 0 / 50%)
      border-radius: 20px
      overflow: hidden
      background: linear-gradient(66.45deg, #FFFEF0 15.94%, #FFFDEA 29.45%, #FFF8CF 46.45%, #FDF7D7 76.99%, #FFFCE9 83.48%)
      @media (max-width: $screen-md-min)
        min-width: fit-content

      .modal-content, .modal-footer
        background: none
      .modal-content
        border-radius: 20px
        @media (max-width: $screen-md-min)
          min-width: fit-content

      .modal-footer
        span
          color: $gray

  .modal-content
    display: flex
    flex-direction: column
    min-height: 550px
    max-height: 850px
    max-width: fit-content
    min-width: 850px
    text-align: center
    padding: 0
    border: none

  // General modal stuff

  button.back
    position: absolute
    color: $teal
    left: 20px
    top: 20px
    text-decoration-line: underline
    font-weight: bold
    font-size: 18px
    line-height: 25px
    z-index: 1
    border: none
    background: none

  button.close
    top: 20px
    right: 20px
    position: absolute
    z-index: 1

  .close, .back
    color: white
    opacity: 0.5
    right: 7px
    &:hover
      opacity: 0.9


  .modal-header
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-end
    height: 100px
    max-height: 10.5vh
    padding: 0

    background-color: $navy

    h3
      color: white

  .modal-footer
    padding: 0
    margin: 0
    height: 50px
    display: flex
    align-items: center
    justify-content: center

    background-color: $navy

    span
      color: white
      
    a span
      text-decoration: underline

  #choose-account-type-view, #segment-check-view, #eu-confirmation-view, #oz-vs-coc-view, #basic-info-view, #coppa-deny-view, #single-sign-on-already-exists-view, #single-sign-on-confirm-view, #extras-view, #confirmation-view
    display: flex
    flex-direction: column
    flex-grow: 1

  .modal-body
    display: flex
    flex-direction: column
    flex-grow: 1

  .modal-body-content
    flex-grow: 2
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 20px 0

  // Back/forward buttons

  .history-nav-buttons
    width: 100%
    display: flex
    flex-direction: row-reverse
    flex-grow: 1
    align-items: flex-end
    justify-content: space-between

    .btn
      // Undo .style-flat's .btn + .btn margin
      margin: 0

    // Left-align when there's only a back button
    .back-button:first-child:last-child
      margin-right: auto

  // Forms

  .form-container
    width: 800px

    .form-group
      text-align: left

  .full-name
    display: flex
    flex-direction: row

  .form-group
    display: flex
    flex-direction: column
    align-content: flex-start

    &.text-center
      text-align: center

    input
      height: 40px

    &.row
      display: block

    &.last-initial
      margin-left: 30px
      width: auto

      input
        width: 70px

    &.subscribe
      width: 100%

  // Fancy text inside horizontal rules

  .hr-text
    position: relative
    hr
      width: 430px
      padding: 0
      border: none
      border-top: thin solid #444
      color: #444
    span
      position: absolute
      left: 50%
      top: 0.45em
      transform: translateX(-50%)
      padding: 0 0.75em
      font-weight: bold
      font-size: 10pt
      background: white


  // Glyphicon colors

  .glyphicon-ok-circle
    color: green

  .glyphicon-remove-circle
    color: red

// Additional syles added for the Ozaria ClassCode variant of the segment-check
#create-account-modal .modal-header.ozaria-modal-header
  justify-content: space-between
  flex-direction: row

  & > *
    flex: 1
  & > *:nth-child(2)
    flex: 3

  border-radius: 20px 20px 0 0
  padding: 0 15px
  background: linear-gradient(66.45deg, #FFFEF0 15.94%, #FFFDEA 29.45%, #FFF8CF 46.45%, #FDF7D7 76.99%, #FFFCE9 83.48%)
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06)
  z-index: 1

  h3
    color: #232323
    font-family: Arvo
    font-style: normal
    font-weight: bold
    font-size: 33px
    line-height: 41px

  button.close
    opacity: 0.85
    max-width: 25px

#segment-check-view.student
  border-radius: 0 0 20px 20px
  background: linear-gradient(66.45deg, #FFFEF0 15.94%, #FFFDEA 29.45%, #FFF8CF 46.45%, #FDF7D7 76.99%, #FFFCE9 83.48%)

.ozaria-modal
  .primary-oz-button
    font-family: Open Sans
    font-style: normal
    font-weight: bold
    font-size: 18px
    line-height: 38px
    color: $pitch
    background-color: $teal
    border: 0
    padding: 5px 15px
    border-radius: 4px
    text-align: center

    min-width: 220px
    min-height: 50px

    &[disabled]
      cursor: not-allowed
