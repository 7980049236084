#basic-info-view
  .network-login
    transition: filter 0.1s linear

    &:hover
      filter: brightness(110%)

  .gplus-login
    margin-bottom: 20px

    .gplus-logo
      width: 254px

  .facebook-login

    #facebook-signup-btn
      height: 40px

      .facebook-logo
        height: 40px

  .clever-login

    .clever-logo
      // Match size of Google button
      width: 254px
      height: 52px

  // Forms

  .basic-info
    display: flex
    flex-direction: column

    .form-group
      text-align: left
      margin: 0

      &[dir="rtl"]
        text-align: right

      input
        max-height: 5vh

      .help-block
        float: right

      .fancy-error
        padding-top: 29px
        line-height: 14px

    .form-container
      > .form-group, > .row
        max-height: 84px
        max-height: calc(29px + 29px + 5vh)
        flex-grow: 1
        align-self: flex-start

  .btn-illustrated img
    // Undo previous opacity-toggling hover behavior
    opacity: 1

  label
    margin-bottom: 0

  .help-block
    margin: 0

  .optional-help-block
    font-style: italic

  .form-container
    width: 800px

  .input-lg
    padding-top: 0
    padding-bottom: 0
    padding-left: 12px
    padding-right: 12px

  .optional-text
    font-size: 12px

  #want-in-school
    position: absolute
    bottom: 18px

    #want-in-school-checkbox
      margin-top: 7px
