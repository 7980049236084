
/* These styles are global. This is required so bootstrap.... :( */
@import "app/styles/bootstrap/variables";
@import "app/styles/mixins";
@import "app/styles/style-flat-variables";

#main-nav.navbar {
  background: white;
  margin-bottom: 0;
  white-space: nowrap; // prevent home icon from going under brand
  box-shadow: unset;
  font-weight: 400;
  display: none;

  @media print {
    display: none;
  }

  h5 {
    font-family: "Arvo", serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 31px;
    font-variant: normal;
    color: black;
    margin: 0;
  }

  p, .text-p, .text-p button {
    font-family: $body-font;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.75px;
    line-height: 26px;
  }

  #create-account-link {
    background-color: $teal;
    color: white;
    border: 1px solid $teal;
    border-radius: 4px 0 0 4px;
    width: 131px;
    &:hover {
      background-color: #2DCEC8;
      border: 1px solid #2DCEC8;
      transition: background-color .35s, border .35s;
    }
  }

  #login-link {
    width: 94px;
    border: 1px solid $teal;
    border-radius: 0 4px 4px 0;
    /*color: $teal;*/ /* too faint for WCAG AAA */
    color: #16837f; /* increased contrast by lowering luminance */
    background: transparent;
    &:hover {
      background-color: #1FBAB4;
      color: white;
      transition: color .35s, background-color .35s;
    }
  }
  .nav-spacer{
    height: 12px;
  }

  .navbar-browser-recommendation {
    margin-left: 1em;
    padding-top: 15px;

    a {
      font-size: 16px;
      padding: 10px 15px;
      float: left;
      &:hover {
        color: $teal;
        text-decoration: none;
      }
    }
  }

  .login-buttons {
    margin: 2px 70px 0px 10px;
    @media (max-width: $screen-md-min) {
      display: inline-block;
      margin: 2px 10px 29.5px;
    }
    @media (max-width: $wider-breakpoint) {
      margin-right: 10px;
    }
    & li {
      display: inline-block;
    }
    & button {
      line-height: 20px;
    }
  }
  a.navbar-brand {
    padding: 14px 0 16px 70px;
    margin: 0px;
    @media (max-width: $wider-breakpoint) {
      padding-left: 10px;
    }

    #logo-img {
      height: 40px;

      &.powered-by {
        height: 30px;
        width: auto;
        margin-top: -5px;
      }
    }

    .code-ninjas-logo, #tarena-logo, .tecmilenio-logo {
      height: 40px;
      width: auto;
      margin-right: 10px;
    }
  }

  .navbar-toggle {
    color: black;
    margin: 30px 70px 0;
    border-color: $navy;
    @media (max-width: 767px) {
      margin: 15px 10px 0;
    }

    .icon-bar {
      background-color: $navy;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    #navbar-collapse {
      float: right;
    }

    .dropdown-menu {
      max-width: 330px;
      overflow-x: auto;
    }
  }
  .language-dropdown {
    max-height: 60vh;
    overflow-y: auto;
    left: -55px;
  }

  #navbar-collapse {
    max-height: 100vh;

    .text-teal {
      /*color: $teal;*/ /* too faint for WCAG AAA */
      color: #16837f; /* increased contrast by lowering luminance */
      font-weight: 600;  /* increased contrast by increasing weight */
    }
  }

  .nav > li > a, .nav > li > button {
    // TODO: Move this to bootstrap variables for navbars

    // TODO: getting overridden by .navbar .nav > li > a for some reason
    font-family: $body-font;
    text-shadow: unset;
    padding: 10px 15px;
    @media (max-width: $wider-breakpoint) {
      padding: 10px 10px;
    }

    color: $navy;
    &:hover {
      color: $teal;
    }
  }
  // TODO: what is this for?
  .nav > li.disabled > a, .nav > li.disabled > button {
    color: black;
    &:hover {
      background: white;
      color: black;
      cursor: default;
    }
  }

  .new-pill {
    font-size: 16px;
    font-weight: 600;
    background-color: #ff76c1;
    border-radius: 14px;
    padding: 4px;
    margin-left: 5px;
  }

  .dropdown-hover .dropdown-menu {
    padding: 0;
  }

  @media (min-width: $grid-float-breakpoint) {
    .dropdown-hover:hover {
      & > ul {
        /* Allows for mouse over to expand dropdown */
        display: unset;
      }
    }
  }

  .dropdown-hover .dropdown-menu li a {
    height: 50px;
    display: flex;
    align-items: center;
    color: #0E4C60;
  }

  @media (max-width: $grid-float-breakpoint) {
    .nav > li > a, .nav > li > button {
      padding: 10px 20px;
      height: 45px;
    }
    .language-dropdown-item {
      color: $navy;
    }
    .account-dropdown-item {
      color: $navy;
    }

    .dropdown-hover .dropdown-menu li a {
      justify-content: center;
    }

    .dropdown-menu.pull-right {
      /* Important required for bootstrap overwriting */
      float: unset !important;
    }
  }

  // TODO: still used?
  .img-circle {
    border: $gold 8px solid;
    width: 98px;
    height: 98px; // Includes the border
  }

  .img-circle-small {
    border: $gold 3px solid;
    width: 33px;
    height: 33px;
  }

  // For teacher avatars
  .border-burgundy {
    border-color: $navy;
  }

  .border-navy {
    border-color: $navy;
  }

  span.unreadMessage {
    width: 5px;
    height: 5px;
    position: absolute;
    top: 10px;
    left: 45px;
    border-radius: 50%;
    background-color: $yellow;
    box-shadow: 0 0 2px 2px $yellow;
  }

  span.unread {
    width: 1.2em;
    height: 1.2em;
    margin-left: 1em;
    line-height: 1.2em;
    border-radius: 50%;
    background-color: $yellow;
    color: white;
    display: inline-block;
    margin-left: 0.5em;
  }

  .dashboard-toggle {
    border-radius: 8px;
    margin: 8px 15px;
    border: 1px solid #131b25;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .dashboard-button {
      padding: 6px 15px;
      margin: 0px;

      a {
        color: #131b25;
        text-decoration: none;
      }
    }

    .active {
      border-radius: 8px;
      background: #f7d047;

      a {
        color: #131b25;
      }
    }

    .show-divider:not(:last-child) {
      border-right: 1px solid #131b25;
    }
  }
}

nav#main-nav.navbar.dark-mode {
  background-color: #0C1016;

  .nav > li > a {
    color: #FCBB00;

    &:hover {
      color: #FF39A6;
    }
  }

  .dashboard-toggle {
    border: 1px solid #FCBB00;

    & > .show-divider {
      border-right: 1px solid #FCBB00 !important;
    }
  }

  .dashboard-toggle .dashboard-button a {
    color: #FCBB00;
  }

  .dropdown-menu {
    background-color: white;
  }

  #create-account-link {
    background-color: #FCBB00;
    border: 1px solid #FCBB00;
    color: #0C1016;

    &:hover {
      background-color: #FF39A6;
      border: 1px solid #FF39A6;
    }
  }

  #login-link {
    color: #FCBB00;
    border: 1px solid #FCBB00;

    &:hover {
      background-color: #FF39A6;
      border: 1px solid #FF39A6;
      color: #0C1016;
    }
  }
}
