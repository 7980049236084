@import "app/styles/style-flat-variables"

#hero-select-view
  width: 100%

  .hero-list
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin-bottom: -50px

  .hero-option
    display: flex
    flex-direction: column
    align-items: center
    margin: 0 25px 25px

  .hero-avatar
    margin: 6px
    background-color: #f8f8f8
    box-shadow: 0 0 0 1px gray

  .current .hero-avatar
    box-shadow: 0 0 0 6px gray

  .selected .hero-avatar
    box-shadow: 0 0 0 6px $gold
