@import "app/styles/mixins"

.hints-view
  position: relative

  width: 500px // TODO: should be in sync with surface min-width
  padding: 26px 32px
  //border-style: solid
  //border-image: url(/images/level/popover_border_background.png) 16 12 fill round
  //border-width: 16px 12px
  @include box-shadow(0 0 0 #000)
  @include user-select(initial)

  border-radius: 8px
  -webkit-backdrop-filter: blur(88.4px)
  backdrop-filter: blur(88.4px)
  box-shadow: inset -44.2px 44.2px 44.2px 0 rgba(255, 255, 255, 0.1), inset 44.2px -44.2px 44.2px 0 rgba(36, 39, 46, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.25)
  border: solid 2px #152934
  background-color: #152934
  color: #e7e7e7
  font-family: $ls-font
  font-size: 16px

  h1, h2, h3, h4, h5, h6
    color: #e7e7e7
    font-family: $ls-font

  .btn.btn-illustrated
    border-image: url(/images/level/little-sophia/run_button.png) 47 53 47 37 fill stretch
    color: #e7e7e7
    padding: 6px

    &:active
      border-image: url(/images/level/little-sophia/run_button_active.png) 47 53 47 37 fill stretch

  .close-hint-btn
    position: absolute
    right: 5px
    top: 5px

    .glyphicon-remove
      position: relative
      top: 4px

  h1
    margin-bottom: 30px

  .btn-area
    margin-top: 5px

  .hint-title
    font-size: 18px
    text-transform: uppercase

  .hint-body
    height: 80%
    overflow-y: auto
    img
      width: 100%

  .hint-pagination
    font-size: 18px
    margin-top: 0px
    text-transform: uppercase

  pre
    box-sizing: content-box 
    padding: 0
    @include user-select(none)
    .ace_content
      .ace_marker-layer
        .ace_bracket
          display: none
        .ace_selection
          display: none
      .ace_cursor-layer
        .ace_cursor
          display: none
