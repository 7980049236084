
@import "ozaria/site/styles/common/variables.scss";

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.column {
  flex-direction: column;
}

// These types of buttons could be shared better
.btn-primary.btn-moon {
  background-color: $moon;
  border-radius: 1px;
  color: $gray;
  text-shadow: unset;
  font-weight: bold;
  @include font-h-5-button-text-black;
  min-width: 260px;
  padding: 15px 0;
  background-image: unset;
  margin: 0 15px;

  &:hover {
    @include font-h-5-button-text-white;
    background-color: $goldenlight;
    transition: background-color .35s;
  }
}
