
.modal
  ::v-deep .container
    width: 100%
    height: 100%

    padding: 25px
    border-radius: 10px

.modal-content
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

  .modal-header, .modal-body, .modal-footer
    display: flex
    justify-content: center
    align-items: center
    width: inherit
    padding: 5px

  .modal-header
    font-weight: bold
    font-size: 30px
    position: relative

  .modal-body
    font-size: 20px
    flex-grow: 1
    padding-top: 0px
