#oz-vs-coco-view
  text-align: left
  width: 1167px
  .modal-contents-container
    width: 100%
  .modal-container
    border-radius: 6px
  .modal-body, .modal-body-content
    padding: 0
  .modal-content
    border: 0
  .modal-header
    display: none
  .primary-oz-button, .continue-codecombat
    text-transform: uppercase

  .close-button
    border: 0
    text-transform: none
    -webkit-appearance: button
    background: none
    img
      width: 25px
      height: 25px
  .header
    width: 100%
    display: flex
    flex-direction: row
    justify-content: end
    justify-content: flex-end
    position: absolute
  img
    &.oz-logo
      height: 78.75px
      width: auto
      margin-bottom: 35px
    &.coco-logo
      height: 50px
      width: auto
  .modal-contents-container
    display: flex
    flex-direction: row
    min-height: inherit
  .ozaria-encourage-container
    flex-shrink: 2
    flex-grow: 2
    flex-basis: 2
    flex: 2
    background: white
    background: url(/images/ozaria/modal/encouragement-modal/SpritLandReveal_Gradient.png) left bottom no-repeat, url(/images/ozaria/modal/encouragement-modal/Hero.png) left bottom no-repeat
    background: url(/images/ozaria/modal/encouragement-modal/Hero.png) 22px bottom no-repeat, url(/images/ozaria/modal/encouragement-modal/SpritLandReveal_Gradient.png) left bottom no-repeat, linear-gradient(66.45deg, #FFFEF0 15.94%, #FFFDEA 29.45%, #FFF8CF 46.45%, #FDF7D7 76.99%, #FFFCE9 83.48%)
    border-radius: 6px 0 0 6px
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    padding: 40px 0

  .codecombat-continue-container
    flex-shrink: 1
    flex-grow: 1
    flex-basis: 1
    flex: 1
    background-color: #173F41
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    h3
      font-family: Open Sans
      font-style: normal
      font-variant: normal
      font-weight: bold
      font-size: 22px
      line-height: 30px
      color: white
    li
      font-family: Open Sans
      font-style: normal
      font-weight: 300
      font-size: 18px
      line-height: 25px
      color: white
      margin: 10px 0
    ul
      margin: 10px 0 40px
    button
      font-family: Open Sans
      font-style: normal
      font-weight: bold
      font-size: 18px
      line-height: 25px
      text-align: center
      color: #1FBAB4
      background-color: unset
      border: 1px solid
      padding: 5px 15px
      height: 50px
      text-transform: capitalize
      border-radius: 4px




  #ozaria-cta
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
  .ozaria-row
    display: flex
    flex-direction: row
    align-items: center
    width: 90%
    max-width: 520px
    img
      width: 80px
      height: 80px
      margin-right: 30px
    p
      font-family: Open Sans
      font-style: normal
      font-weight: 300
      font-size: 18px
      line-height: 25px
      color: #232323
    h4
      font-family: Open Sans
      font-style: normal
      font-weight: bold
      font-size: 22px
      line-height: 30px
      color: black
      margin: 2px 0
      font-variant: normal

#create-account-modal
  .modal-footer.screen-oz-vs-coco
    display: none
