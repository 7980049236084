#coppa-deny-view
  .parent-email-blurb
    width: 500px

  .parent-email-input-group
    display: flex
    align-items: center
    text-align: center
    
    .glyphicon
      width: 0
      line-height: 40px
      font-size: 30px

  .error
    color: red
